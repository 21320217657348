<template>
  <div>
    <el-container>
      <el-aside width="auto">
        <div class="myinput">
          <el-input placeholder="输入关键字进行过滤" v-model="filterText">
          </el-input>
        </div>

        <span class="mytree">
          <el-tree lazy :highlight-current="highlightCurrent" :load="loadNode" :props="defaultProps"
            @node-click="handleNodeClick" ref="tree" accordion @node-expand="handleNodeExpand"
            @node-collapse="handleNodeCollapse" :filter-node-method="filterNode"></el-tree>
        </span>
      </el-aside>
      <el-main>
        <el-row :span="24" :gutter="10">
          <el-col :span="20">
              <el-pagination v-show="dataArray.length > 3"
                :currentPage="curIndex"
                pager-count="10"
                layout="prev, pager, next, jumper"
                :page-count="loadIndex"
                @current-change="handleCurrentChange"
              />
          </el-col>
          <!-- <el-col :span="4">
              <el-button type="danger" size="medium" icon="el-icon-data-analysis" v-show="dataArray.length > 3"
                  @click='toggleLegend()'>显示 | 隐藏图例</el-button>
          </el-col> -->
        </el-row>
        
        <div class="btngroup">
          <transition name="el-zoom-in-center">
            <el-row :span="24" :gutter="1">
              <el-col :span="1">
                <div class="space_holder"></div>
              </el-col>
              <el-col :span="3">
                <span id="myselect">
                  <el-select v-model="value" placeholder="请选择页数" v-show="dataArray.length > 3"
                    @change="onSelectChange(value)">
                    <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </span>
              </el-col>
              <el-col :span="1">
                <div class="space_holder"></div>
              </el-col>
              <el-col :span="2">
                <el-button type="success" size="medium" icon="el-icon-caret-left" circle v-show="dataArray.length > 3"
                  @click='prevPage()'></el-button>
              </el-col>
              <el-col :xs="6" :sm="6" :md="5" :lg="4" :xl="3">
                <div class="page" v-show="dataArray.length > 3">第 {{this.curIndex}} 页 | 共 {{this.loadIndex}} 页</div>
              </el-col>
              <el-col :span="2">
                <el-button type="warning" size="medium" icon="el-icon-caret-right" circle v-show="dataArray.length > 3"
                  @click='nextPage()'></el-button>
              </el-col>
              <el-col :xs="6" :sm="6" :md="7" :lg="8" :xl="9">
                <div class="space_holder"></div>
              </el-col>
              <el-col :span="3">
                <el-button type="danger" size="medium" icon="el-icon-data-analysis" v-show="dataArray.length > 3"
                  @click='toggleLegend()'>显示 | 隐藏图例</el-button>
              </el-col>
            </el-row>
          </transition>
        </div>
        <div class="box">
            <div id="container" class="container" ref="linechart"></div>
        </div>
          
      </el-main>
    </el-container>
  </div>
</template>


<script>
import Highcharts from 'highcharts/highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighNoDataToDisplay from 'highcharts/modules/no-data-to-display';
import Highmaps from 'highcharts/modules/map';
import HighExporting from 'highcharts/modules/exporting'
import HighExportData from 'highcharts/modules/export-data'
import HighOffLineExporting from 'highcharts/modules/offline-exporting'
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighGridLight from 'highcharts/themes/grid-light.js'
//import HighBoost from 'highcharts/modules/boost'
import { getYear, getKeti, getKetiDate, getFileName, getFileData } from '../../axios/data.js'

HighchartsMore(Highcharts)
HighchartsDrilldown(Highcharts);
Highcharts3D(Highcharts);
Highmaps(Highcharts);
HighNoDataToDisplay(Highcharts)
HighExporting(Highcharts)
HighExportData(Highcharts)
HighOffLineExporting(Highcharts)
HighGridLight(Highcharts)
//HighBoost(Highcharts)

export default {
  name: 'curve',
  data () {
    return {
      filterText: '',
      loadFinish: false,
      chart: null,
      highlightCurrent: true,
      showLegend: false,
      defaultProps: {
        children: 'children',
        label: 'label',
        isLeaf: 'isLeaf'
      },
      loading: null,
      loadText: '',
      loadIndex: 1,
      curIndex: 1,
      lastClickFileInfo: {
        year: '',
        ketiname: '',
        ketidate: '',
        filename: ''
      },
      dataArray: [],
      options: [],
      value: ''
    };
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val);
    },
    loaded (val) {
      var percentage = (parseFloat(val) / parseFloat(this.total) * 100.0).toFixed(1)
      this.loading.text = "正在向服务器请求数据, 请稍候~~     " + "当前进度:   "
        + `${percentage}` + "   %"
    },
    windowHeight (val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
      this.$refs.linechart.style.width = this.windowwidth >= 1440 ? 80 + 'vw' : 75 + 'vw'
      this.$refs.linechart.style.height = 65 + 'vh'
    },
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
      
      this.$refs.linechart.style.width = this.windowwidth >= 1440 ? 80 + 'vw' : 75 + 'vw'
      this.$refs.linechart.style.height = 65 + 'vh'
    }
  },
  computed: {
    domain () {
      return this.$store.state.tab.domain
    },
    domain443 () {
      return this.$store.state.tab.domain443
    },
    loaded () {
      return this.$store.state.tab.loaded
    },
    total () {
      return this.$store.state.tab.total
    },
    windowWidth () {
      return this.$store.state.tab.windowWidth
    },
    windowHeight () {
      return this.$store.state.tab.windowHeight
    },
    titlelist () {
      return this.$store.state.user.titlelist
    }
  },
  mounted () {
    this.initCharts()  
    this.refreshStyle()
  },
  methods: {
    handleCurrentChange(val) {
        this.curIndex = val
        this.value = this.curIndex.toString()
        this.refreshCurve(this.lastClickFileInfo.filename)
    },
    refreshStyle () {
      var Box = document.querySelectorAll('.box')
      for (var i = 0; i < Box.length; i++) {
        Box[i].style.height = 80 + 'vh'
      }
      console.log('this.windowWidth', this.windowWidth)
    },
    initCharts () {
      this.$refs.linechart.style.width = this.windowwidth >= 1440 ? 80 + 'vw' : 75 + 'vw'
      this.$refs.linechart.style.height = 65 + 'vh'
      Highcharts.setOptions({
        global: {
          useUTC: false
        }
        ,
        lang: {
          rangeSelectorZoom: '范围选择',
          downloadPNG: '下载PNG图片',
          downloadCSV: '下载CSV文件',
          downloadJPEG: '下载JPG文件',
          downloadSVG: '下载SVG文件',
          printChart: '打印曲线',
          noData: '当前没有数据',
          loading: '正在努力加载数据, 请稍候！'
        }
      });

      this.chart = Highcharts.chart({
        chart: {
          renderTo: 'container',
          type: 'line',
          animation: false,
          zoomType: 'x',
          style: {
            fontFamily: "Helvetica, sans-serif, 'Microsoft YaHei'",
            fontSize: "0.833vw",
            color: "#8990A7"
          },
          events:
          {
            selection: function (event) {
              // 打印 x 轴最大值及最小值（即范围）
              //console.log(event)
              if (event.resetSelection) {
                //console.log(this.options)
                this.xAxis[0].setExtremes(event.target.xAxis[0].min,
                  event.target.xAxis[0].max, false)
                this.redraw();
                this.reflow();
              }
            },
            click: function (e) {
              this.redraw();
              this.reflow();
            }
          }
        },
        title: {
          text: null
        },
        noData: {
            style: {
                "fontSize": "0.938vw", 
                "fontWeight": "bold", 
                "color": "#8990A7"
            }
        },
        legend: { enabled: true },
        credits: { enabled: false },
        navigator: {
          height: 30,
          margin: 10
        },
        exporting: {
          filename: Highcharts.dateFormat('%Y%m%d_%H%M%S', new Date().getTime()),
          fallbackToExportServer: false,
          buttons: {
            contextButton: {
              menuItems: [
                'downloadPNG',
                'separator',
                'downloadCSV',
                'separator',
                'printChart'
              ]
            }
          }
        },
        mapNavigation: {
          enabled: true,
          enableButtons: false
        },
        tooltip: {
          split: false,
          shared: true,
          animation: false,
          xDateFormat: '%y-%m-%d %H:%M:%S',
          useHTML: true,
          headerFormat: '<span style="color: #DA1C5D; font-size: 0.625vw;">{point.key}</span><table>',
          pointFormat: '<tr><td style="color: {series.color}">{series.name}: </td>' +
            '<td style="text-align: right; padding-left: 0.521vw;">{point.y}</td>{valueSuffix}',
          footerFormat: '</tr></table>',
          valueDecimals: 1,
          crosshairs: {
            width: 2,
            color: 'gray',
            dashStyle: 'shortdot'
          }
        },
        xAxis: {
          type: 'datetime',
          //minRange: 60000,
          //maxRange: 120000,
          dateTimeLabelFormats: {
            millisecond: '%Y<br/>%m-%d<br/>%H:%M:%S',
            second: '%Y<br/>%m-%d<br/>%H:%M:%S',
            minute: '%Y<br/>%m-%d<br/>%H:%M:%S',
            hour: '%Y<br/>%m-%d<br/>%H:%M:%S',
            day: '%Y<br/>%m-%d<br/>%H:%M:%S',
            week: '%Y<br/>%m-%d<br/>%H:%M:%S',
            month: '%Y<br/>%m-%d<br/>%H:%M:%S',
            year: '%Y<br/>%m-%d<br/>%H:%M:%S'
          }
        },
        yAxis: {
          tickAmount: 4,
          allowDecimals: false,
          opposite: false,
          lineWidth: 1,
          softMin: -20,
          softMax: 80
        },
        boost: {
          seriesThreshold: 1
        },
        plotOptions: {
          series: {
            lineWidth: 1,
            animation: false,
            findNearestPointBy: 'x',
            marker: {
              enabled: false
            },
            states: {
              hover: {
                enabled: true,
                lineWidth: 1,
                halo: {
                  size: 5
                }
              }
            },
            dataGrouping: {
              dateTimeLabelFormats: {
                millisecond: ['%y-%m-%d %H:%M:%S'],
                second: ['%y-%m-%d %H:%M:%S'],
                minute: ['%y-%m-%d %H:%M:%S'],
                hour: ['%y-%m-%d %H:%M:%S'],
                day: ['%y-%m-%d %H:%M:%S'],
                week: ['%y-%m-%d %H:%M:%S'],
                month: ['%y-%m-%d %H:%M:%S'],
                year: ['%y-%m-%d %H:%M:%S']
              }
            }
          }
        }
      });
    },
    filterNode (value, data) {
      if (!value) return true;
      if (value.length < 4) return true
      if (value.length > 4 && value.length < 9) return true
      if (value.length > 9) {
        if (value.indexOf('_') === -1) return true
      }
      var valid = data.year.indexOf(value) !== -1
        || data.ketiname.indexOf(value) !== -1
      return valid;
    },
    async checkFileName (path, year, ketiname, ketidate, resolve) {
      this.openFullScreen()
      var array = []
      try {
        var res = await getFileName(path, year, ketiname, ketidate)
        var respText = JSON.parse(res.request.responseText);

        if (respText.status === "true") {
          for (var i = 0; i < respText.message.length; i++) {
            var name = respText.message[i]
            var index = name.lastIndexOf("/");
            name = name.substring(index + 1);
            var chobj = {
              label: name,
              year: year,
              ketiname: ketiname,
              ketidate: ketidate,
              isLeaf: true,
              children: []
            }
            array.push(chobj)
          }
        }
      } catch (error) {
        this.$message.warning(error)
      }
      this.closeFullScreen()
      return resolve(array)
    },
    async checkKetiDate (path, year, ketiname, resolve) {
      this.openFullScreen()
      var array = []
      try {
        var res = await getKetiDate(path, year, ketiname)
        var respText = JSON.parse(res.request.responseText);

        if (respText.status === "true") {
          for (var i = 0; i < respText.message.length; i++) {
            var name = respText.message[i]
            var index = name.lastIndexOf("/");
            name = name.substring(index + 1);
            var chobj = {
              label: name,
              year: year,
              ketiname: ketiname,
              ketidate: '',
              isLeaf: false,
              children: []
            }
            array.push(chobj)
          }
        }
      } catch (error) {
        this.$message.warning(error)
      }
      this.closeFullScreen()
      return resolve(array)
    },
    async checkKeti (path, year, resolve) {
      this.openFullScreen()
      var array = []
      try {
        var res = await getKeti(path, year)
        var respText = JSON.parse(res.request.responseText);

        if (respText.status === "true") {
          for (var i = 0; i < respText.message.length; i++) {
            var keti = respText.message[i]
            var index = keti.lastIndexOf("/");
            keti = keti.substring(index + 1);
            let isValid = this.checkStar(this.titlelist)
            console.log("checkStar is Valid: ", isValid)
            if(!isValid)
            {
              console.log('titlelist', this.titlelist)
               isValid = this.titlelist.indexOf(keti) !== -1
               console.log("checkKeti is Valid: ", keti, isValid)
            }

            if(isValid)
            {
              var chobj = {
                label: keti,
                year: year,
                ketiname: keti,
                ketidate: '',
                isLeaf: false,
                children: []
              }
              array.push(chobj)
            }
          }
        }
      } catch (error) {
        this.$message.warning(error)
      }
      this.closeFullScreen()
      return resolve(array)
    },
    async checkYear (path, resolve) {
      this.loadText = '正在向服务器请求数据, 请稍候~~'
      this.openFullScreen()
      var array = []
      try {
        var res = await getYear(path)
        var respText = JSON.parse(res.request.responseText);

        if (respText.status === "true") {
          for (var i = 0; i < respText.message.length; i++) {
            var yearname = respText.message[i]
            var index = yearname.lastIndexOf("/");
            yearname = yearname.substring(index + 1);
            var year = parseInt(yearname)
            if (year >= 2000 && year <= 2100) {
              var obj = {
                label: yearname,
                year: yearname,
                ketiname: '',
                ketidate: '',
                children: [],
                isLeaf: false
              }
              array.push(obj)
            }
          }
        }
      } catch (error) {
        this.$message.warning(error)
      }
      this.closeFullScreen()
      return resolve(array)
    },
    async checkGet (path, year, ketiname, ketidate, filename) {
      this.loadText = '正在向服务器请求数据, 请稍候~~'
      this.openFullScreen()
      this.chart.showLoading();
      try {
        var res = await getFileData(path, year, ketiname, ketidate, filename)
        //var res = await getFileContent(path, year, ketiname, ketidate, filename)
        //console.log(res.request.responseText)
        //console.log(this.loaded)
        var respText = res.request.responseText;

        while (this.chart.xAxis.length > 1) {
          this.chart.xAxis[1].remove(false);
        }

        while (this.chart.yAxis.length > 1) {
          this.chart.yAxis[1].remove(false);
        }

        while (this.chart.series.length > 0) {
          this.chart.series[0].remove(false);
        }

        //chart.destroy();
        this.chart.redraw()
        this.chart.reflow()
        var xIndex = 0, yIndex = 0;
        var lineCount = 0;
        this.dataArray = respText.split("\n").filter(item => item !== "");
        //console.log(this.dataArray.length)

        var datalength = 1000
        if (filename.indexOf('.mdt') !== -1) {
          datalength = 20000
        }
        if (filename.indexOf('.mdh') !== -1) {
          datalength = 5000
        }
        this.loadIndex = Math.floor(this.dataArray.length / datalength)
        if (this.dataArray.length % datalength > 0)
          ++this.loadIndex

        this.options = []
        for (let i = 0; i < this.loadIndex; i++) {
          let obj = {
            value: (i + 1).toString(),
            label: (i + 1).toString()
          }
          this.options.push(obj)
        }

        this.value = this.curIndex.toString()


        if (lineCount >= this.dataArray.length) {
          this.chart.redraw()
          this.chart.reflow()
          this.closeFullScreen()
          this.chart.hideLoading(); return;
        }

        var axesStrList = this.dataArray[lineCount++].split("|").filter(item => item !== "");

        axesStrList.forEach(str => {
          var DataType = 0, AxisColor = 6, RangeLower = 4, RangeUpper = 5, AxisName = 2, LineName = 2;
          var axisStrList = str.split(":");
          if (axisStrList.length !== AxisColor + 1) {
            this.closeFullScreen()
            this.chart.hideLoading(); return;
          }

          var dataType = axisStrList[DataType].toLowerCase();
          if (dataType == "datetime") {
            var t = new Date().getTime();

            if (xIndex === 0) {
              if (this.chart.xAxis[xIndex]) {
                this.chart.xAxis[xIndex].setExtremes(t, t + 24 * 3600 * 1000, false);
                this.chart.xAxis[xIndex].setTitle({ text: axisStrList[AxisName] }, false);
                this.chart.xAxis[xIndex].update({
                  className: axisStrList[AxisName],
                  id: axisStrList[AxisName],
                  gridLineDashStyle: 'Dot'
                });
              }
              ++xIndex;
            }
            else {
              this.chart.addAxis({
                type: 'datetime',
                dateTimeLabelFormats: {
                  millisecond: '%m-%d<br/>%H:%M:%S',
                  second: '%m-%d<br/>%H:%M:%S',
                  minute: '%m-%d<br/>%H:%M',
                  hour: '%m-%d<br/>%H:%M',
                  day: '%Y<br/>%m-%d',
                  week: '%Y<br/>%m-%d',
                  month: '%Y-%m',
                  year: '%Y'
                }
              }, true, false);
              if (this.chart.xAxis[xIndex]) {
                this.chart.xAxis[xIndex].setExtremes(t, t + 24 * 3600 * 1000, false);
                this.chart.xAxis[xIndex].setTitle({ text: axisStrList[AxisName] }, false);
                this.chart.xAxis[xIndex].update({
                  className: axisStrList[AxisName],
                  id: axisStrList[AxisName],
                  gridLineDashStyle: 'Dot'
                });
              }
              ++xIndex;
            }
          }
          else if (dataType == "double") {
            if (yIndex === 0) {
              var lower = parseFloat(axisStrList[RangeLower]);
              var upper = parseFloat(axisStrList[RangeUpper]);
              if (this.chart.yAxis[yIndex]) {
                this.chart.yAxis[yIndex].setExtremes(lower, upper, false);
                this.chart.yAxis[yIndex].setTitle({ text: axisStrList[AxisName] === '湿球温度' ? '湿度' : axisStrList[AxisName] }, false);
                this.chart.yAxis[yIndex].update({
                  className: axisStrList[LineName],
                  id: axisStrList[LineName],
                  gridLineDashStyle: 'Dot'
                });
              }
              ++yIndex;
            }
            else {
              lower = parseFloat(axisStrList[RangeLower]);
              upper = parseFloat(axisStrList[RangeUpper]);
              this.chart.addAxis({
                tickAmount: 4,
                allowDecimals: false,
                opposite: yIndex % 2 === 0 ? false : true,
                lineWidth: 1,
                softMin: -20,
                softMax: 80
              }, false, false);
              if (this.chart.yAxis[yIndex]) {
                this.chart.yAxis[yIndex].setExtremes(lower, upper, false);
                this.chart.yAxis[yIndex].setTitle({ text: axisStrList[AxisName] === '湿球温度' ? '湿度' : axisStrList[AxisName] }, false);
                this.chart.yAxis[yIndex].update({
                  className: axisStrList[LineName],
                  id: axisStrList[LineName],
                  gridLineDashStyle: 'Dot'

                });
              }
              ++yIndex;
            }
          }
        })
        if (lineCount >= this.dataArray.length) {
          this.chart.redraw()
          this.chart.reflow()
          this.closeFullScreen()
          this.chart.hideLoading(); return;
        }
        var namePairList = this.dataArray[lineCount++].split("|").filter(item => item !== "");

        namePairList.forEach(namePair => {
          var LineColorType = 3, KeyAxis = 0, ValueAxis = 1, LineName = 2;
          var index = namePair.indexOf(":");
          if (index > 0 && index < namePair.length - 1) {
            var axisNameList = namePair.split(":");
            if (axisNameList.length >= LineColorType + 1) {
              var xAxisIndex = 0, yAxisIndex = 0;
              this.chart.xAxis.forEach(function (axis, index) {
                if (axis.userOptions.className == axisNameList[KeyAxis]) {
                  xAxisIndex = index;
                  return false;
                }
              })

              this.chart.yAxis.forEach(function (axis, index) {
                if (axis.userOptions.className == axisNameList[ValueAxis]) {
                  yAxisIndex = index;
                  return false;
                }
              })

              this.chart.addSeries({
                name: axisNameList[LineName],
                boostThreshold: 1000,
                turboThreshold: 1,
                data: [],
                xAxis: xAxisIndex,
                yAxis: yAxisIndex,
                color: axisNameList[LineColorType],
                findNearestPointBy: 'x',
                showInLegend: this.showLegend
              });
            }
          }
        })

        if (lineCount >= this.dataArray.length) {
          this.chart.redraw()
          this.chart.reflow()
          this.closeFullScreen()
          this.chart.hideLoading(); return;
        }
        this.titleList = this.dataArray[lineCount++].split(",").filter(item => item !== "");
        if (lineCount >= this.dataArray.length) {
          this.chart.redraw()
          this.chart.reflow()
          this.closeFullScreen()
          this.chart.hideLoading(); return;
        }
        lineCount = (this.curIndex - 1) * datalength;
        if (lineCount === 0) lineCount += 3
        var dataList = this.dataArray[lineCount++].split(",").filter(item => item !== "");
        if (this.titleList.length === 0 || dataList.length === 0) {
          this.chart.redraw()
          this.chart.reflow()
          this.closeFullScreen()
          this.chart.hideLoading(); return;
        }
        if (this.titleList.length !== dataList.length) {
          this.chart.redraw()
          this.chart.reflow()
          this.closeFullScreen()
          this.chart.hideLoading(); return;
        }
        var eachNameList = [];
        var eachTimeValueList = [];

        var tickTime = parseFloat(dataList[0]) * 1000;
        var tickFirstTime = parseFloat(dataList[0]) * 1000;

        for (i = 1; i < this.titleList.length; i++) {
          eachNameList.push([this.titleList[i]]);
          if (!eachTimeValueList[i - 1])
            eachTimeValueList[i - 1] = [];
          eachTimeValueList[i - 1].push([tickTime, parseFloat(dataList[i])]);
        }
        //console.log(this.dataArray.length)
        while (lineCount < (this.dataArray.length > this.curIndex * datalength ? this.curIndex * datalength : this.dataArray.length)) {
          dataList = this.dataArray[lineCount++].split(",").filter(item => item !== "");
          if (this.titleList.length === 0 || dataList.length === 0) {
            this.chart.redraw()
            this.chart.reflow()
            this.closeFullScreen()
            this.chart.hideLoading(); return;
          }
          if (this.titleList.length !== dataList.length) {
            this.chart.redraw()
            this.chart.reflow()
            this.closeFullScreen()
            this.chart.hideLoading(); return;
          }
          tickTime = parseFloat(dataList[0]) * 1000;
          for (var i = 1; i < dataList.length; i++) {
            //eachNameList.push([titleList[i]]);
            if (!eachTimeValueList[i - 1])
              eachTimeValueList[i - 1] = [];
            eachTimeValueList[i - 1].push([tickTime, parseFloat(dataList[i])]);
          }
        }

        this.chart.series.forEach(function (serie, index) {
          //console.log(index)
          //console.log(serie.name)
          eachNameList.forEach(function (name, index1) {
            var namestring = name.toString()
            if (serie.name.trim() === namestring.trim()) {
              //console.log(serie.name, name, "setData")
              serie.setData(eachTimeValueList[index1], false);
              //return false;
            }
          });
        });

        this.chart.xAxis[0].setExtremes(tickFirstTime, tickTime, false);


        setTimeout(() => {

          if (this.chart) {
            this.chart.redraw()
            this.chart.reflow()
            this.chart.hideLoading();
            this.closeFullScreen()
          }
        }, 500)
      } catch (error) {
        this.$message.warning(error)
        this.chart.redraw()
        this.chart.reflow()
        this.chart.hideLoading();
        this.closeFullScreen()
      }

    },
    refreshCurve (filename) {
      this.loadText = '正在向服务器请求数据, 请稍候~~'
      this.openFullScreen()
      this.chart.showLoading()
      setTimeout(() => {
        var datalength = 1000
        if (filename.indexOf('.mdt') !== -1) {
          datalength = 20000
        }
        if (filename.indexOf('.mdh') !== -1) {
          datalength = 5000
        }
        var lineCount = (this.curIndex - 1) * datalength;
        if (lineCount === 0) lineCount += 3
        var dataList = this.dataArray[lineCount++].split(",").filter(item => item !== "");
        if (this.titleList.length === 0 || dataList.length === 0) {
          this.chart.redraw()
          this.chart.reflow()
          this.closeFullScreen()
          this.chart.hideLoading(); return;
        }
        if (this.titleList.length !== dataList.length) {
          this.chart.redraw()
          this.chart.reflow()
          this.closeFullScreen()
          this.chart.hideLoading(); return;
        }
        var eachNameList = [];
        var eachTimeValueList = [];

        var tickTime = parseFloat(dataList[0]) * 1000;
        var tickFirstTime = parseFloat(dataList[0]) * 1000;

        for (i = 1; i < this.titleList.length; i++) {
          eachNameList.push([this.titleList[i]]);
          if (!eachTimeValueList[i - 1])
            eachTimeValueList[i - 1] = [];
          eachTimeValueList[i - 1].push([tickTime, parseFloat(dataList[i])]);
        }

        while (lineCount < (this.dataArray.length > this.curIndex * datalength ? this.curIndex * datalength : this.dataArray.length)) {
          dataList = this.dataArray[lineCount++].split(",").filter(item => item !== "");
          if (this.titleList.length === 0 || dataList.length === 0) {
            this.chart.redraw()
            this.chart.reflow()
            this.closeFullScreen()
            this.chart.hideLoading(); return;
          }
          if (this.titleList.length !== dataList.length) {
            this.chart.redraw()
            this.chart.reflow()
            this.closeFullScreen()
            this.chart.hideLoading(); return;
          }
          tickTime = parseFloat(dataList[0]) * 1000;
          for (var i = 1; i < dataList.length; i++) {
            //eachNameList.push([titleList[i]]);
            if (!eachTimeValueList[i - 1])
              eachTimeValueList[i - 1] = [];
            eachTimeValueList[i - 1].push([tickTime, parseFloat(dataList[i])]);
          }
        }

        this.chart.series.forEach(function (serie, index) {
          //console.log(index)
          //console.log(serie.name)
          eachNameList.forEach(function (name, index1) {
            var namestring = name.toString()
            if (serie.name.trim() === namestring.trim()) {
              //console.log(serie.name, name, "setData")
              serie.setData(eachTimeValueList[index1], false);
              //return false;
            }
          });
        });

        this.chart.xAxis[0].setExtremes(tickFirstTime, tickTime, false);


        setTimeout(() => {

          if (this.chart) {
            this.chart.redraw()
            this.chart.reflow()
            this.chart.hideLoading()
            this.closeFullScreen()
          }

        }, 500)
      }, 500)
    },
    handleNodeClick (data) {
      //console.log('hanleclick', data)     
      if (data.label.indexOf(".mdt") === -1 && data.label.indexOf(".mds") === -1
        && data.label.indexOf(".mdv") === -1 && data.label.indexOf(".mdh") === -1) {
        return;
      }
      if (data.year === this.lastClickFileInfo.year && data.ketiname === this.lastClickFileInfo.ketiname
        && data.ketidate === this.lastClickFileInfo.ketidate && data.label === this.lastClickFileInfo.filename) {
        return;
      }
      this.curIndex = 1
      this.loadIndex = 1
      this.titleList = []
      this.dataArray = []
      let obj = {}
      obj.year = data.year
      obj.ketiname = data.ketiname
      obj.ketidate = data.ketidate
      obj.filename = data.label
      this.lastClickFileInfo = obj
      this.checkGet(this.domain, data.year, data.ketiname, data.ketidate, data.label)
      //this.checkGet(this.domain443, data.year, data.ketiname, data.ketidate, data.label)
    },
    openFullScreen () {
      this.loading = this.$loading({
        lock: true,
        text: this.loadText,
        spinner: 'el-icon-loading',
        customClass: 'create-isLoading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      //console.log(this.loading)

    },
    closeFullScreen () {
      this.loading.close();
      if (this.chart) {
        this.chart.redraw()
        this.chart.reflow()
      }
      this.loadText = '正在向服务器请求数据, 请稍候~~'
    },
    loadNode (node, resolve) {
      //console.log(node)
      if (node.level === 0) {
        this.checkYear(this.domain, resolve)
      }
      else if (node.level === 1) {
        this.checkKeti(this.domain, node.data.label, resolve)
      }
      else if (node.level === 2) {
        this.checkKetiDate(this.domain, node.parent.data.label, node.data.label, resolve)
      }
      else if (node.level === 3) {
        this.checkFileName(this.domain, node.parent.parent.data.label, node.parent.data.label, node.data.label, resolve)
      }
      else {
        return resolve([])
      }
    },
    handleNodeExpand (data) {
      //console.log('handleNodeExpand', data)
      setTimeout(() => {
        if (this.chart) {
          this.chart.redraw()
          this.chart.reflow()
        }
      }, 500)
    },
    handleNodeCollapse (data) {
      //console.log('handleNodeCollapse', data)
      setTimeout(() => {
        if (this.chart) {
          this.chart.redraw()
          this.chart.reflow()
        }
      }, 500)
    },
    nextPage () {
      //console.log('nextPage')
      if (this.curIndex < this.loadIndex) {
        ++this.curIndex
        this.value = this.curIndex.toString()
        //  this.checkGet(this.domain, this.lastClickFileInfo.year,this.lastClickFileInfo.ketiname, this.lastClickFileInfo.ketidate,this.lastClickFileInfo.filename)
        //this.checkGet(this.domain443, this.lastClickFileInfo.year, this.lastClickFileInfo.ketiname, this.lastClickFileInfo.ketidate, this.lastClickFileInfo.filename)
        this.refreshCurve(this.lastClickFileInfo.filename)
      }
    },
    prevPage () {
      //console.log('prevPage')
      if (this.curIndex > 1) {
        --this.curIndex
        this.value = this.curIndex.toString()
        //this.checkGet(this.domain, this.lastClickFileInfo.year,this.lastClickFileInfo.ketiname, this.lastClickFileInfo.ketidate,this.lastClickFileInfo.filename)
        //this.checkGet(this.domain443, this.lastClickFileInfo.year,this.lastClickFileInfo.ketiname, this.lastClickFileInfo.ketidate,this.lastClickFileInfo.filename)
        this.refreshCurve(this.lastClickFileInfo.filename)
      }

    },
    toggleLegend () {
      this.loadText = '正在努力执行该操作, 该操作需要一段时间, 请耐心等待'
      this.openFullScreen()
      setTimeout(() => {
        this.showLegend = !this.showLegend
        this.chart.series.forEach((serie) => {
          serie.update({
            showInLegend: this.showLegend
          }, false);
        })

        this.chart.redraw()
        this.chart.reflow()
        this.closeFullScreen()

      }, 500)
    },
    onSelectChange (val) {
      var curIndex = parseInt(val)
      if (curIndex !== this.curIndex) {
        this.curIndex = curIndex
        this.refreshCurve(this.lastClickFileInfo.filename)
      }
    },
    checkStar(titleList) {
        return titleList.indexOf("*") !== -1
    }
  }
}
</script>

<style lang="scss">
.space_holder {
  display: flex;
  font-size: 1.042vw;
  align-items: center;
  justify-content: center;
  min-height: 1.563vw;
}

.create-isLoading {
  .el-loading-spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7);
    padding: 1.042vw;
    border-radius: 0.208vw;
    width: auto;
    text-align: center;
    position: absolute;

    i {
      color: #eee;
    }

    .el-loading-text {
      color: #dbe276;
      font-size: 0.833vw;
    }
  }
}
</style>

<style lang='scss' scoped>
.box {
  margin: -7px;
  padding: 0px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.containter {
  margin: 0px;
  padding: 0;
}


.el-main {
  padding-top: 0px;
  background-color: #1389bc;
 
}

.el-container {
  height: 100%;
  background-color: #333;
  border: 1px solid #333;
}

.container {
  border: 0.052vw solid red;
}

.myinput ::v-deep {
  .el-input {
    margin-top: 30px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    width: auto;
  }

  .el-input__inner {
    font-size: 14px;
    color: white;
    background-color: #1f1f1f;
    border: 0.052vw solid darkcyan;
    margin-top: 0px;
  }
}

#myselect {
  // 未选中任何选项的时候 placeholder的样式 需要先选中父元素 增加权重
  ::v-deep input::-webkit-input-placeholder {
    color: #fff;
  }
  ::v-deep input::-moz-input-placeholder {
    color: #fff;
  }
  ::v-deep input::-ms-input-placeholder {
    color: #fff;
  }

  //修改的是el-input的样式
  //一种方法是设置最里层el-input__inner的背景色 外层的两级父元素设置为透明色
  //另一种方法是从el-select到el-input__inenr的背景色都设置为需要的颜色
  ::v-deep .el-select,
  ::v-deep .el-input,
  ::v-deep .el-input__inner {
    background-color: darkcyan;
    color: #fff;
    border: none;
    border-radius: 0.26vw;
    text-align: center;
  }

  //el-input聚焦的时候 外层的border会有一个样式
  ::v-deep .el-select .el-input.is-focus .el-input__inner {
    border: 0;
  }

  //修改的是el-input上下的小图标的颜色
  ::v-deep .el-select .el-input .el-select__caret {
    color: #fff;
  }

  //修改总体选项的样式 最外层
  ::v-deep .el-select-dropdown {
    background-color: #08164d;
    margin: 0;
    border: 0;
    border-radius: 0;
  }

  //修改单个的选项的样式
  ::v-deep .el-select-dropdown__item {
    background-color: transparent;
    color: #fff;
  }

  //item选项的hover样式
  ::v-deep .el-select-dropdown__item.hover {
    color: #409eff;
  }

  //修改的是下拉框选项内容上方的尖角
  ::v-deep .el-popper .popper__arrow,
  .el-popper .popper__arrow::after {
    display: none;
  }
}

.btngroup {
  margin: 0.521vw auto;
  justify-content: space-around;
}

.mytree ::v-deep {
  overflow: hidden;
  
  .el-tree-node {
    position: relative;
    padding-left: 0px;
    // border: 1px solid #000;
    background-color: #000;
  }
  //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
  // .el-tree-node__expand-icon.is-leaf {
  //   display: none;
  // }
  .el-tree .el-tree-node__expand-icon.expanded {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .el-tree-node__label {
    width: auto;
    float: right;
    font-family: “Trebuchet MS”, Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin: 10px;
    overflow: hidden;
    color: #fff;
    line-height: 1.302vw;
    letter-spacing: 0.052vw;
    &:hover {
      color: black;
    }
  }

  .el-tree-node:focus > .el-tree-node__content {
    background-color: pink; //背景色
  }
  .el-tree-node__content {
    &:hover {
      background: lightgreen;
      .el-tree-node__label {
        color: white;
      }
    }
  }

  .el-tree-node__content {
    display: flex;
    align-items: center;
    height: 30px;
    cursor: pointer;
}

  .el-tree .el-icon-caret-right:before {
    content: "\e723";
    font-size: 16pt;
    color: green;
    position: absolute;
    left: -0.042vw;
    top: -0.26vw;
  }
  .el-tree .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
    content: "\e722";
    font-size: 16pt;
    color: green;
    position: absolute;
    left: -0.042vw;
    top: -0.26vw;
  }
}

.el-pagination {
    white-space: nowrap;
    padding: 2px 5px;
    margin-left: -20px;
    color: #1f1f1f;
    font-weight: 700;
}

.page {
  color: #1f1f1f;
  border-radius: 0.26vw;
  padding: 0.417vw;
}

.space_holder {
  min-height: 1.563vw;
}
</style>